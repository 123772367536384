<template>
  <tr>
    <td class='pr-2 py-2 border-r'>
      <button
        @click='openContactEdit(contact)'
        class='underline hover:no-underline px-3 py-2 border border-transparent hover:border-blue-300 hover:text-gray-900 rounded hover:bg-gray-50'>
        {{contact.contact_name}}
      </button>
      <portal to='modals'>
        <contact-edit
          v-if='showEditContact'
          :display-in-modal='true'
          @updated-contact='doneEditingContact' />
      </portal>
    </td>
    <td class='px-2 py-2 border-r'>{{contact.entity_name}}</td>
    <td class='px-2 py-2 border-r'>{{contact.office_phone_number}}</td>
    <td class='px-2 py-2 border-r'>{{contact.phone_number}}</td>
    <td class='px-2 py-2 border-r'>{{contact.fax_number}}</td>
    <td class='px-2 py-2 border-r truncate'>
      <a :href='mailtoLink(contact.email)'
        class='hover:underline'>
        {{contact.email}}
      </a>
    </td>
    <td class='px-2 py-2 border-r'>
      <input class='form-input w-full'
        v-model='contact.role'
        @change='setContactLinkEdited'>
    </td>
    <td class='px-2 py-2 w-32 border-r'>
      <button
        @click='selectSmsContact'
        class='lg:block border rounded-md text text-gray-700 border-gray-200 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <chat-alt-icon/></button>
    </td>
    <td class='px-2 py-2 w-16 text-center'>
      <button class='inline-block'
        @click='setContactToDelete'>
        <x-circle-icon class='inline-block text-red-700 opacity-75'/>
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { ChatAltIcon, XCircleIcon } from '@vue-hero-icons/outline'
import ContactEdit     from '@/components/contacts/ContactEdit.vue'

export default {
  name: 'InvestmentContactsRow',
  components: {
    ChatAltIcon,
    ContactEdit,
    XCircleIcon,
  },
  props: [
    'contact',
    'canSms',
  ],
  data () {
    return {
      showEditContact: false,
    }
  },
  methods: {
    ...mapActions('contacts', [
      'editContact',
      'getContactCustomFields',
    ]),
    doneEditingContact (contact) {
      console.log('updated contact, need to update in list: ', contact)
    },
    openContactEdit (contact) {
      this.showEditContact = true
      this.editContact(contact)
      this.getContactCustomFields(contact.id)
      this.$store.dispatch('modalOpen')
    },
    mailtoLink (email) {
      return `mailto:${email}`
    },
    setContactLinkEdited () {
      this.contact.updated_on_client = true
    },
    setContactToDelete () {
      this.setContactLinkEdited()
      this.contact.to_delete = true
    },
    selectSmsContact () {
      this.$emit('selectSmsContact', this.contact)
    },
  },
}
</script>
